import "../style.scss"
import React from "react"

const ContentData = [
  {
    icon: "assets/svgs/painpoint_1.svg",
    title: "Missing clients",
    des: "Failing to turn website visitors into potential customers.",
  },
  {
    icon: "assets/svgs/painpoint_2.svg",
    title: "Customer frustration",
    des: "Delayed responses causing frustrated and dissatisfied clients.",
  },
  {
    icon: "assets/svgs/painpoint_3.svg",
    title: "Wasting money",
    des:
      "Incurring extra costs through multiple, inefficient communication platforms.",
  },
]

const Page2 = () => {
  return (
    <div className="page page2">
      <div className="page2-title">Why let your business suffer? Time for a change.</div>

      {ContentData.map((item, index) => {
          return (
            <div className={`page2-item item-${index}`} key={`point-${index}`}>
              <img src={item.icon} width={32} height={32}  loading='lazy' />
              <p className="title">{item.title}</p>
              <p className="des">{item.des}</p>
            </div>
          )
        })}
    </div>
  )
}
export default Page2
