import "./style.scss"
import React from "react"

const StartNowBlok = () => {
  return (
    <section className="start-now-section">
      <div className="wrapper">
        <div className="top">
          <img
            src="https://a.storyblok.com/f/177206/x/d110ff2629/logo-icon.svg"
            width={60}
            height={60}
            loading="lazy"
          />
          Never miss or lose a prospect again
        </div>

        <a href="/register">
          <div className="btn">Try for Free</div>
        </a>

        <div className="buttom">For free. Easy setup</div>
      </div>
    </section>
  )
}
export default StartNowBlok
