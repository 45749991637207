import React, { useState } from "react"

const Tryfree = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toLogin = () => {
    if(typeof window !== "undefined" ){
      window.location.href = '/register'
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 2000);
    }
  }
  return (
    <div className="mobile-page-tryFree">
      <div onClick={toLogin}>
        <div className="mobile-page-tryFree-btn">
          {isLoading &&
            <div className="loadingBtn">
              <div></div>
              <div></div>
              <div></div>
            </div>
          }
          {
            !isLoading && <span>Try for Free &rarr;</span>
          }
        </div>
      </div>
    </div>
  )
}
export default Tryfree
