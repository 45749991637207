import "./style.scss"
import "./header.scss"
import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import TryFree from "./tryFree"
import ArrowNext from "./arrowNext"
import pages, { preload, imgList } from './pageItems'

const MobileHomePage = () => {
  preload(imgList);
  const [currentPage, setCurrentPage] = useState(0);
  const handleChangePage = () => {
    const swiper = document.querySelector('.swiper').swiper;
    swiper.slideNext();
  }

  return (
    <>
      <Swiper className="mobile-page" direction={"vertical"} onSlideChange={({activeIndex}) => setCurrentPage(activeIndex)}>
        {pages.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              {item}
            </SwiperSlide>
          )
        })}
      </Swiper>
      <ArrowNext pageIndex={currentPage} handleChangePage={handleChangePage} />
      <TryFree />
    </>
  )
}
export default MobileHomePage
