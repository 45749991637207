import React, { useState, useEffect, useLayoutEffect } from "react"
// import HomePage from '../components/HomePage'
import Layout from "../components/Layout"
import LayoutMobile from "../components/Layout/index-mobile"
import { graphql } from "gatsby"
import useStoryblok from "../utils/storyblok"
// import { Container } from "react-bootstrap"
import Page from "../components/Page"
import HomePageContextProvider from "../context/HomePageContextProvider"
import ThreeStepBlok from "../components/CommonComponents/ThreeStep"
import FeatureDisplay from "../components/CommonComponents/FeatureDsiplay"
import StartNowBlok from "../components/CommonComponents/StartNowBlok"
import LatestArticles from "../components/HomeComponents/LatestArticles"
import MobileHomePage from "../components/MobileHomePage"
import { Script } from "gatsby"

import SEO from "../components/Layout/SEO"

// import TeamPersonalHomeBanner from "../components/HomeComponents/TeamPersonalHomeBanner"
// import BusinessCnnection from "../components/HomeComponents/BusinessCnnection"
// import Brands from "../components/HomeComponents/Brands"
// import Results from "../components/HomeComponents/Results"
// import EmpowerTeam from "../components/HomeComponents/EmpowerTeam"
// import Feedback from "../components/HomeComponents/Feedback"
// import PhysicalOffice from "../components/HomeComponents/PhysicalOffice"
// import Articles from "../components/HomeComponents/Articles"
// import ConnectSection from "../components/HomeComponents/ConnectSection"

// storyblok_access_token: SbwMRbr7HN0wMEk8QraDrgtt
// space_id: 142999
console.log("index")
const isMobileType = target =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(target)

export default function PageIndex({ data, location }) {
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? isMobileType(navigator.userAgent) : false
  )

  const checkDisplayMode = () => {
    if (typeof window !== "undefined") {
      const preMode = isMobile
      const next =
        isMobileType(navigator.userAgent) || window.innerWidth <= 1000
      if (preMode === next) {
        return
      }
      setIsMobile(next)
    }
  }

  const story = useStoryblok(data.story, location)
  story.content.body = story.content.body.slice(0, 4)

  useEffect(() => {
    checkDisplayMode()
    if (typeof window !== "undefined") {
      const isLoadingEl = document.querySelector(".is-loading")
      isLoadingEl && document.body.removeChild(isLoadingEl)
      // window.addEventListener("resize", checkDisplayMode, { passive: true })
    }
  }, [])

  const Static_Content = () => (
    <>
      <SEO meta={story.content.meta} locationPath={location.pathname} />
      <div style={{ visibility: "hidden" }}>
        <Layout>
          <HomePageContextProvider>
            <Page blok={story.content} location={location} />
          </HomePageContextProvider>
          <ThreeStepBlok />
          <FeatureDisplay />
          <LatestArticles />
          <StartNowBlok />
        </Layout>
      </div>
    </>
  )

  return typeof window === "undefined" ? (
    <Static_Content />
  ) : (
    <>
      {isMobile && (
        <LayoutMobile>
          <MobileHomePage />
        </LayoutMobile>
      )}
      {!isMobile && (
        <Layout>
          <HomePageContextProvider>
            <Page blok={story.content} location={location} />
          </HomePageContextProvider>
          <ThreeStepBlok />
          <FeatureDisplay />
          <LatestArticles />
          <StartNowBlok />
          {/* <Script src="https://cubo-us.s3.us-east-2.amazonaws.com/cubo-mangement/prodImage/widget-Assistant-1600-250321ba-0b06-4112-bd2a-b52b68b28b1c.js"
            onLoad={() => console.log("success")}
          /> */}
        </Layout>
      )}
    </>
  )
}

export const query = graphql`
  {
    story: storyblokEntry(slug: { eq: "homepage" }) {
      name
      content
      full_slug
      uuid
    }
  }
`
