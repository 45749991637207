import "../style.scss"
import React from "react"
import { imgList } from './index'

const pageData = {
  title: 'Build your online presence and feel together with our virtual office',
  des: "Easy to connect with your clients from anywhere. You'll feel like you're all in the same place, even if you're not.",
}

const Page6 = () => {
  return (
    <div className="page page6">
      <div className="page6-title">{pageData.title}</div>
      <div className="page6-des">{pageData.des}</div>

      <img className="above" src={imgList.info} alt="" />

      <div className="yellow"></div>
      <div className="blue"></div>
    </div>
  )
}
export default Page6
