import React from "react"
import DynamicComponent from "./DynamicComponent"
import { sbEditable } from "@storyblok/storyblok-editable"
import SEO from "./Layout/SEO"

const Page = ({ blok, location }) => {
  const content =
    blok.body &&
    blok.body.map(childBlok => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ))
  const hasTitle =
    blok.title && blok.title.length ? (
      <h1 className="text-5xl font-bold font-serif text-primary tracking-wide text-center py-8">
        {blok.title}
      </h1>
    ) : null
  return (
    <>
      <SEO meta={blok.meta} locationPath={location.pathname} />
      <div {...sbEditable(blok)}>
        {/* { hasTitle } */}
        {content}
      </div>
    </>
  )
}

export default Page
