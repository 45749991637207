import React, { useEffect } from "react"
import Header from "./Header"
import CookiesSetting from "../cookiesSetting"
import PopDialog from "../TryFreeComponents/PopDialog"
import { addAnalysisTool } from "./analytics-tool"

const Layout = ({ children }) => {
  addAnalysisTool()
  return (
    <div className="mobile">
      <noscript>
        <iframe
          title="Hidden"
          src="https://www.googletagmanager.com/ns.html?id=GTM-T4WTN9R"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <noscript>
        <img
          style={{ display: "none", visibility: "hidden" }}
          src="https://www.facebook.com/tr?id=142300305429136&ev=PageView&noscript=1"
        />
      </noscript>
      <Header />
      {children}
      <CookiesSetting />
      <PopDialog />
    </div>
  )
}

export default Layout
