import "../style.scss"
import React from "react"
import { imgList } from './index'

const pageData = {
  title: 'And use Cubo to simplify your tool stack and save money',
  des1: "Link-in-Bio tool to replace Linktree",
  des2: 'Scheduler link to replace Calendly',
  des3: 'Meeting link to replace Zoom',
  des: 'All links lead the traffic directly to your online office for long-term engagement.',
}

const Page10 = () => {
  return (
    <div className="page page10">
      <div className="page10-title">{pageData.title}</div>
      <div className="page10-des">
        <div className="point"></div>
        {pageData.des1}</div>
      <div className="page10-des">
        <div className="point"></div>
        {pageData.des2}</div>
      <div className="page10-des">
        <div className="point"></div>
        {pageData.des3}</div>
      <div className="page10-des">{pageData.des}</div>

      <img className="above img" src={imgList.mobileHome} alt="" />

      <div className="yellow"></div>
      <div className="blue"></div>
    </div>
  )
}
export default Page10
