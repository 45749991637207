import "../style.scss"
import React from "react"
import { imgList } from './index'

const pageData = {
  title: 'Get your prospects convinced by your reputation before they reach out.',
  des1: "Your link-in-bio and intro video can showcase your business and yourself. ",
  des2: 'And the long string of visitors will demonstrate how popular you are.',
}

const Page7 = () => {
  return (
    <div className="page page7">
      <div className="page7-title">{pageData.title}</div>
      <div className="page7-des">{pageData.des1}</div>
      <div className="page7-des">{pageData.des2}</div>

      <img className="above visitor" src={imgList.visitor} alt="" />
      <img className="above socialLinks" src={imgList.socialLinks} alt="" />

      <div className="yellow"></div>
      <div className="blue"></div>
    </div>
  )
}
export default Page7
