import "../style.scss"
import React from "react"
import { imgList } from './index'

const pageData = {
  title: 'Or stay engaged through chat and scheduling options',
  des: "Guests can also send you messages, or schedule a meeting when you are busy or offline.",
}

const Page9 = () => {
  return (
    <div className="page page9">
      <div className="page9-title">{pageData.title}</div>
      <div className="page9-des">{pageData.des}</div>

      <img className="above schedule" src={imgList.schedule} alt="" />
      <img className="above chat" src={imgList.chat} alt="" />

      <div className="yellow"></div>
      <div className="blue"></div>
    </div>
  )
}
export default Page9
