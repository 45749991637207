import "../style.scss"
import React from "react"
import { imgList } from './index'

const ContentData = {
  des: 'Forget Zoom, Calendly, Slack, and Linktree. Get all the features in one platform for all types of business connections.',
}

const Page3 = () => {
  return (
    <div className="page page3">
      <div className="page3-title">
        1 Online Office. <br />
        4 Powerful Tools. <br />
        Win Contracts and Save Big.
      </div>

      <div className="page3-des">{ ContentData.des }</div>

      <img className="page3-img" src={imgList.function} />
    </div>
  )
}
export default Page3
