import "../style.scss"
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import { imgList } from './index'

const styleText = [
  { text: "Prospects", width: 248 },
  { text: "Partners", width: 212 },
  { text: "Clients", width: 176 },
  { text: "Website Visitors", width: 405 },
]
const Page1 = () => {
  return (
    <div className="page page1">
      <div className="page1-title">
        <span>Stay Closer to Your</span>
        {/* <span className="color">Website Visitors</span> */}
        <Swiper
          style={{ height: 39 }}
          modules={[Autoplay]}
          direction={"vertical"}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
          loop={true}
          spaceBetween={4}
          autoHeight={true}
          className="index_swiper"
        >
          {styleText.map((item, index) => {
            return <SwiperSlide key={index}>
              <span style={{color: '#8C4AF8'}}>{item.text}</span>
            </SwiperSlide>
          })}
        </Swiper>
        <span>in Person, Online</span>
      </div>

      <div className="page1-content">Cubo is the #1 online office that boosts your business connections with online presence, DMs, video calls, scheduling, and link-in-bio all in one simple link.</div>

      <img className="img" src={imgList.mobileHome} alt="" />
    </div>
  )
}
export default Page1
