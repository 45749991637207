import "../style.scss"
import React from "react"
import { imgList } from './index'

const pageData = {
  title: 'One-click for guests to start video chats when they come by',
  des: "Guests would like to tap your avatar to initiate video talks while seeing you there in an in-person way.",
}

const Page8 = () => {
  return (
    <div className="page page8">
      <div className="page8-title">{pageData.title}</div>
      <div className="page8-des">{pageData.des}</div>

      <img className="above owner" src={imgList.owner} alt="" />
      <img className="above waitingZone" src={imgList.waitingZone} alt="" />

      <div className="yellow"></div>
      <div className="blue"></div>
    </div>
  )
}
export default Page8
