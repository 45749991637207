import React, { useState, useEffect } from "react"

let timer = '';
const ArrowNext = ({ pageIndex, handleChangePage }) => {
  const [isShowArrow, setIsShowArrow] = useState(false);
  useEffect(() => {
    clearTimeout(timer);
    setIsShowArrow(false)
    timer = setTimeout(() => {
      setIsShowArrow(pageIndex < 10)
    }, 2000);
  }, [pageIndex])

  const nextPage = () => {
    isShowArrow && handleChangePage();
  }

  return (
    <div className={`arrowImg ${isShowArrow ? 'show' : 'hide'}`} onClick={nextPage}>
      <div className='changePage'>
        <img src="/assets/svgs/arrow.svg" alt="" />
      </div>
    </div>
  )
}
export default ArrowNext
