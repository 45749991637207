import "../style.scss"
import React from "react"

const pageData = {
  title: 'Boom Your Clients and Contracts in 3 Steps',
  list: [
    'Set up office in 2 minutes',
    'Share office link everywhere',
    'Stay in office and respond'
  ]
}

const Page5 = () => {
  return (
    <div className="page page5">
      <div className="page5-title">{pageData.title}</div>

      <div className="page5-list above">
        {pageData.list.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="no">{index + 1}</div>
              <div className="text">{item}</div>
            </div>
          )
        })}
      </div>

      <div className="yellow"></div>
      <div className="blue"></div>
    </div>
  )
}
export default Page5
