import Page1 from './page1'
import Page2 from './page2'
import Page3 from './page3'
import Page4 from './page4'
import Page5 from './page5'
import Page6 from './page6'
import Page7 from './page7'
import Page8 from './page8'
import Page9 from './page9'
import Page10 from './page10'
import FooterDisplayBlok from '../../CommonComponents/FooterDisPlay'

export default [Page1, Page2, Page3, Page4, Page5, Page6, Page7, Page8, Page9, Page10, FooterDisplayBlok]

const baseUrl = 'https://a.storyblok.com'

export const imgList = {
  mobileHome: `${baseUrl}/f/177206/750x572/03432dae92/mobile-home.png`,
  function: `${baseUrl}/f/177206/960x960/6add5b7fc6/ft_dp_5_2x.png`,
  marinaAvatar: `${baseUrl}/f/177206/64x64/01093313b7/marina-avatar.svg`,
  avatar1: `${baseUrl}/f/177206/x/01cf07d07e/avatar1.svg`,
  wilsonAvatar: `${baseUrl}/f/177206/48x48/9358dc3f56/wilson-avatar.svg`,
  kenzieAvatar: `${baseUrl}/f/177206/48x48/e751c148cb/kenzie_avatar.svg`,
  davisAvatar: `${baseUrl}/f/177206/48x48/9e3e103c64/davis-avatar.svg`,
  bryanAvatar: `${baseUrl}/f/177206/48x48/34475a0d83/bryan-avatar.svg`,
  info: `${baseUrl}/f/177206/960x960/318b8972e6/ft_dp_1_2x.webp`,
  visitors: `${baseUrl}/f/177206/704x160/e1b0367a63/visitors.png`,
  socialLinks: `${baseUrl}/f/177206/750x496/6571420c01/social-links.png`,
  owner: `${baseUrl}/f/177206/432x284/7676ae7967/owner.png`,
  waitingZone: `${baseUrl}/f/177206/592x299/7f38b2e77a/waiting-zone.png`,
  schedule: `${baseUrl}/f/177206/736x464/d13079c64e/schedule.png`,
  chat: `${baseUrl}/f/177206/750x496/fc46ef75a6/chat.png`,
}

export const preload = (imgs) => {
  const images = new Array();
  const list = Object.values(imgs);
  if(typeof window !== "undefined" ){
    list.forEach((src, i) => {
      images[i] = new Image();
      images[i].src = src;
    })
  }
}

