import "../style.scss"
import React, { useState, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper";
import "swiper/css/pagination";
import { imgList } from './index'

const Person = ({info}) => {
  return (
    <div className="person">
      <div className="person-des">{info.des}</div>
      <div className="person-info">
        <img className="person-avatar" src={info.avatar} alt="" width={48} height={48} />
        <div className="person-name">
          <div className="name">{info.name}</div>
          <div className="position">{info.position}</div>
        </div>
      </div>
      <img src="assets/images/five_star.png" width={160} height={32}   loading='lazy'/>
    </div>
  )
}

const Page4 = () => {
  const pageData = {
    title: 'Loved by All Types of Online Business Owners Worldwide!',
    des: 'Entrepreneurs, Freelancers, Agencies, Consultants, and Coaches…',
    PeopleData: [
      {des:"I've seen a 30% boost in client engagement and a 10% increase in billable hours since I started using it.",avatar:imgList.marinaAvatar,name:'Marina',position:'SEO Agency'},
      {des:" Thanks to Cubo, I've been able to take on 30% more projects while still maintaining a healthy work-life balance ",avatar: imgList.avatar1,name:'Alexander',position:'Freelancer'},
      {des:"Cubo has made it easy for me to build and maintain a professional online presence as a coach. Over 50% signups in 2 months!",avatar:imgList.wilsonAvatar,name:'Wilson',position:'Online Coach'},
  
      {des:"Having a flexible and reliable online office that can adapt to my lifestyle is crucial, and that's exactly what I've found with Cubo. ",avatar:imgList.kenzieAvatar,name:'Kenzie',position:'Digital nomad'},
      {des:"Cubo's out-of-the-box mindset truly enhances the customer satisfaction. It has helped me streamline my services and make a more powerful impact on my clients",avatar: imgList.davisAvatar,name:'Davis',position:'Consultant'},
      {des:"Since using Cubo, I've seen a significant increase in client engagement and revenue. Love it.",avatar:imgList.bryanAvatar,name:'Bryan',position:'Entrepreneur'},
    ]
  }
  return (
    <div className="page page4">
      <div className="page4-title">{pageData.title}</div>
      <div className="page4-des">{pageData.des}</div>

      <Swiper className="personSwiper"
        loop={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        spaceBetween={16}
        pagination={true}
        autoplay={true}
        modules={[Pagination, Autoplay]}
      >
        {pageData.PeopleData.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Person info={item} />
            </SwiperSlide>
          )
        })}
      </Swiper>

      <img loading="lazy" className="page4-users" src="assets/images/user-avatars-mobile.png" alt="Cubo Users" width={320} height={32} />
      <div className="page4-text">Empowering 10k+ small business owners to turn their connections into profits.</div>
    </div>
  )
}
export default Page4
